const style = () => {
  const output = {
    root: {
      width: '100%',
      maxWidth: 1250,
      margin: [0, 'auto'],
      padding: ['calc(30px + 2.997002997002997vw)', 20, 0, 20],
      '&::after': {
        display: 'block',
        content: '" "',
        clear: 'both',
      },
    },
    gridItemContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      position: 'relative',
      float: 'right',
      width: '100%',
      maxWidth: 350,
      height: 227,
      borderRadius: 15,
      maxHeight: 'none',
      '&:nth-child(2)': {
        marginBottom: 26,
      },
      '& a': {
        display: 'block',
      },
    },
    gridItemContainerMain: {
      position: 'relative',
      width: '100%',
      maxWidth: (props) => (props.length <= 1 ? '100%' : 800),
      height: (props) => (props.length <= 1 ? 710 : 480),
      borderRadius: 15,
      marginRight: 60,
      float: 'left',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      objectFit: 'cover',
    },
    gridItemSmall: {
      position: 'relative',
      float: 'right',
      width: '100%',
      maxWidth: 350,
      height: '100%',
      borderRadius: 15,
      maxHeight: 'none',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      overflow: 'hidden',
      objectFit: 'cover',
      background: '#000',
      '&:nth-child(2)': {
        marginBottom: 55,
      },
      '& div': {
        width: '100%',
        height: '100%',
        '& iframe': {
          display: 'block',
          objectFit: 'fit-content',
          width: '100%',
          height: '100%',
          transform: 'none',
          marginTop: 0,
        },
      },
    },
    gridItemBig: {
      position: 'relative',
      width: '100%',
      maxWidth: (props) => (props.length <= 1 ? '100%' : 800),
      height: (props) => (props.length <= 1 ? 680 : 450),
      borderRadius: 15,
      marginRight: 60,
      float: 'left',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      marginBottom: 0,
      objectFit: 'cover',
      background: '#000',
      '& div': {
        width: '100%',
        height: '100%',
        '& iframe': {
          display: 'block',
          objectFit: 'fit-content',
          width: '100%',
        },
      },
    },
    itemLabel: {
      position: 'absolute',
      zIndex: 2,
      top: 0,
      left: 0,
      background: 'transparent',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#fff',
    },
    itemInEvidenza: {
      width: 10,
      height: 10,
      background: (props) => props.customTheme?.ft_item_evidenza,
      position: 'absolute',
      zIndex: 3,
      top: 15,
      left: 15,
      borderRadius: '50%',
      boxShadow: (props) => `0px 0px 10px 2px ${props.customTheme?.ft_item_evidenza}`,
      '-webkit-box-shadow': (props) => `0px 0px 10px 2px ${props.customTheme?.ft_item_evidenza}`,
      animation: '$bounce 2s ease-in-out infinite',
    },
    '@keyframes bounce': {
      '0%': {
        boxShadow: '0px 0px 0px 0px var(--ft-in-evidenza-color)',
        '-webkit-box-shadow': '0px 0px 0px 0px var(--ft-in-evidenza-color)',
      },
      '50%': {
        boxShadow: '0px 0px 10px 5px var(--ft-in-evidenza-color)',
        '-webkit-box-shadow': '0px 0px 10px 5px var(--ft-in-evidenza-color)',
      },
      '100%': {
        boxShadow: '0px 0px 0px 0px var(--ft-in-evidenza-color)',
        '-webkit-box-shadow': '0px 0px 0px 0px var(--ft-in-evidenza-color)',
      },
    },
    isActiveSafari: {
      boxShadow: (props) => `0px 0px 10px 5px ${props.customTheme?.ft_item_evidenza}`,
      '-webkit-box-shadow': (props) => `0px 0px 10px 5px ${props.customTheme?.ft_item_evidenza}`,
      animation: '$bounceActiveSafari 2s ease-in-out infinite',
    },
    '@keyframes bounceActiveSafari': {
      '0%': {
        transform: 'scale(1)',
      },
      '50%': {
        transform: 'scale(1.125)',
        boxShadow: 'none',
        '-webkit-box-shadow': 'none',
      },
      '100%': {
        transform: 'scale(1)',
      },
    },
    itemDescription: {
      fontSize: 11,
      letterSpacing: '0.1em',
      fontWeight: 600,
      textTransform: 'uppercase',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      borderRadius: 15,
      color: (props) => props.customTheme?.text_color,
      background: 'transparent',
      transition: '0.3s ease-out',
      marginTop: 15,
    },
    addDarkOverlay: {
      '&::after': {
        display: 'block',
        content: '""',
        background: 'black',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1,
        opacity: 0.4,
        pointerEvents: 'none',
        borderRadius: 15,
      },
    },
    noPointerEvents: {
      pointerEvents: 'none',
    },
  }

  /*------------------------------
  Custom 1250
  ------------------------------*/
  output['@media (max-width: 1250px)'] = {
    root: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      margin: [0, 'auto'],
      maxWidth: 575,
      padding: ['calc(30px + 2.997002997002997vw)', 0, 0, 0],
    },
    gridItemContainer: {
      float: 'none',
      maxWidth: 575,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      margin: [0, 'auto'],
      '&:nth-child(2)': {
        maxWidth: 275,
        height: 155,
        float: 'left',
        marginBottom: 25,
        marginTop: 25,
      },
      '&:last-child': {
        maxWidth: (props) => (props.length <= 2 ? 574 : 275),
        height: (props) => (props.length <= 2 ? 323 : 155),
        float: 'right',
        marginBottom: 25,
        marginTop: 25,
      },
    },
    gridItemContainerMain: {
      maxWidth: 574,
      maxHeight: 323,
      float: 'none',
      height: 'none',
      borderRadius: 15,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      margin: [0, 'auto'],
    },
    gridItemSmall: {
      float: 'none',
      maxWidth: 575,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      margin: [0, 'auto'],
    },
    gridItemBig: {
      maxWidth: 574,
      maxHeight: 323,
      float: 'none',
      margin: [0, 'auto'],
    },
    itemDescription: {
      display: 'none',
    },
  }

  /*------------------------------
  Custom 615px
  ------------------------------*/
  output['@media (max-width: 615px)'] = {
    root: {
      maxWidth: 375,
      padding: ['calc(30px + 2.997002997002997vw)', 20, 0, 20],
    },
    gridItemContainer: {
      float: 'none',
      maxWidth: 334,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      margin: [0, 'auto'],

      '&:nth-child(2)': {
        float: 'none',
        maxWidth: 334,
        height: 188,
        margin: [25, 'auto'],
      },
      '&:last-child': {
        float: 'none',
        maxWidth: (props) => (props.length <= 2 ? 334 : 334),
        height: (props) => (props.length <= 2 ? 188 : 188),
        margin: [0, 'auto'],
      },
    },
    gridItemContainerMain: {
      height: (props) => (props.length <= 1 ? 188 : 188),
      marginBottom: (props) => (props.length <= 2 ? 25 : null),
    },
    gridItemSmall: {
      float: 'none',
      maxWidth: 334,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      margin: [0, 'auto'],
    },
    itemDescription: {
      display: 'none',
    },
  }

  return output
}

export default style
