import { useState, useEffect, useRef } from 'react'
import classNames from 'classnames'
import { createUseStyles } from 'react-jss'
import DelayLink from '@/components/DelayLink'
import YouTube from 'react-youtube'
import style from './style'

const useStyles = createUseStyles(style)

const LinktreeGrid = ({
  customTheme,
  elements,
}) => {
  const { length } = elements.featured_content.first_content
  const [isSafari, setIsSafari] = useState(false)
  const $root = useRef()
  const ua = navigator.userAgent.toLowerCase()

  const classes = useStyles({ customTheme, length })

  // if i === 0 && video playVideo onEnd
  const onVideoEnd = (e) => {
    e.target.stopVideo()
    e.target.playVideo()
  }

  useEffect(() => {
    if (customTheme) {
      $root.current.style.setProperty('--ft-in-evidenza-color', customTheme.ft_item_evidenza)
    }
  }, [customTheme])

  useEffect(() => {
    if (ua.indexOf('safari') !== -1) {
      if (ua.indexOf('chrome') > -1) {
        // Chrome
      } else {
        // Safari
        setIsSafari(true)
      }
    }
  }, [])

  return (
    <div className={classes.root}>
      {elements.featured_content.first_content.map((item, i) => {
        if (item.ft_video_id !== '' && item.ft_image !== false) {
          return (
            <div
              key={i.toString()}
              className={classNames({
                [classes.gridItemContainer]: true,
                [classes.gridItemContainerMain]: i === 0,
              })}
            >
              <div
                className={classNames({
                  [classes.gridItemSmall]: true,
                  [classes.gridItemBig]: i === 0,
                })}
              >
                <YouTube
                  videoId={item.ft_video_id}
                  id={item.ft_video_id}
                  opts={{
                    playerVars: {
                      autoplay: i === 0 ? 1 : 0,
                      autopause: i === 0 ? 0 : 1,
                      autohide: 1,
                      loop: i === 0 ? 1 : 0,
                      mute: i === 0 ? 1 : 0,
                      controls: 0,
                      showinfo: 0,
                      color: 'white',
                      rel: 0,
                      fs: 0,
                      modestbranding: 1,
                      playsinline: 1,
                    },
                  }}
                  onEnd={onVideoEnd}
                />
              </div>
              <span
                className={classes.itemDescription}
              >
                ↳
                {' '}
                {item.ft_short_description}
              </span>
            </div>
          )
        }
        if (item.ft_video_id !== '' && item.ft_image === false) {
          return (
            <div
              key={i.toString()}
              className={classNames({
                [classes.gridItemContainer]: true,
                [classes.gridItemContainerMain]: i === 0,
              })}
            >
              <div
                className={classNames({
                  [classes.gridItemSmall]: true,
                  [classes.gridItemBig]: i === 0,
                })}
              >
                <YouTube
                  videoId={item.ft_video_id}
                  id={item.ft_video_id}
                  opts={{
                    playerVars: {
                      autoplay: i === 0 ? 1 : 0,
                      autopause: i === 0 ? 0 : 1,
                      autohide: 1,
                      loop: i === 0 ? 1 : 0,
                      mute: i === 0 ? 1 : 0,
                      controls: 0,
                      showinfo: 0,
                      color: 'white',
                      rel: 0,
                      fs: 0,
                      modestbranding: 1,
                      playsinline: 1,
                    },
                  }}
                />
              </div>
              <span
                className={classes.itemDescription}
              >
                ↳
                {' '}
                {item.ft_short_description}
              </span>

            </div>
          )
        }
        if (item.ft_video_id === '' && item.ft_image !== false) {
          return (
            <DelayLink
              key={i.toString()}
              to={item.ft_link}
              className={classNames({
                [classes.gridItemContainer]: true,
                [classes.gridItemContainerMain]: i === 0,
                [classes.noPointerEvents]: item.ft_link === '',
              })}
            >
              <div
                key={i.toString()}
                className={classNames({
                  [classes.gridItemSmall]: true,
                  [classes.gridItemBig]: i === 0,
                  [classes.addDarkOverlay]: true,
                })}
                style={{
                  background: `url(${item.ft_image.url}) no-repeat 50% 50%`,
                  backgroundSize: 'cover',
                }}
              >
                {item.ft_evidenza === true && (
                  <span
                    className={classNames({
                      [classes.itemInEvidenza]: true,
                      [classes.active]: item.ft_evidenza === true,
                      [classes.isActiveSafari]: item.ft_evidenza === true && isSafari,
                    })}
                    ref={$root}
                  />
                )}
                <span className={classes.itemLabel}>{item.ft_label}</span>
              </div>

              <span
                className={classes.itemDescription}
              >
                ↳
                {' '}
                {item.ft_short_description}
              </span>
            </DelayLink>

          )
        }
        return null
      })}
    </div>
  )
}

export default LinktreeGrid
