const height = 50
const style = () => {
  const output = {
    LinktreeLinkRoot: {
      width: '100%',
      maxWidth: 1250,
      margin: ['30px', 'auto'],
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: [0, 20, 0, 20],
    },
    linktreeLinkItem: {
      width: '46vw',
      maxWidth: 575,
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '3.125vw',
    },
    linktreeLink: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: (props) => props.customTheme?.links_background_color,
      minHeight: '7.8125vw',
      maxHeight: 240,
      borderRadius: 15,
      color: (props) => props.customTheme?.links_text_color,
      marginBottom: 10,
      transition: '0.3s ease-out',
      position: 'relative',
      overflow: 'hidden',
    },
    labelDesktop: {
      width: '100%',
      maxWidth: (props) => (props.vw >= 1250 ? 350 : '100%'),
      fontSize: 11,
      letterSpacing: '0.1em',
      fontWeight: 600,
      textTransform: 'uppercase',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      borderRadius: 15,
      color: (props) => props.customTheme?.text_color,
      background: 'transparent',
      transition: '0.3s ease-out',
    },
    active: {
      animation: '$bounce 2s ease-in-out infinite',
    },
    isSafari: {
      background: (props) => props.customTheme?.bounce_effect_background_color,
      color: (props) => props.customTheme?.bounce_effect_text_color,
      animation: '$bounceSafari 2s ease-in-out infinite',
    },
    '@keyframes bounce': {
      '0%': {
        transform: 'scale(1)',
        '-webkit-transform': 'scale(1)',
      },
      '25%': {
        color: 'var(--bounce-text-color)',
      },
      '50%': {
        transform: 'scale(1.025)',
        '-webkit-transform': 'scale(1.025)',
        background: 'var(--bounce-bg-color)',
      },
      '100%': {
        transform: 'scale(1)',
        '-webkit-transform': 'scale(1)',
      },
    },
    '@keyframes bounceSafari': {
      '0%': {
        transform: 'scale(1)',
        '-webkit-transform': 'scale(1)',
      },
      '50%': {
        transform: 'scale(1.025)',
        '-webkit-transform': 'scale(1.025)',
      },
      '100%': {
        transform: 'scale(1)',
        '-webkit-transform': 'scale(1)',
      },
    },
  }

  /*------------------------------
  Custom media query 1250
  ------------------------------*/
  output['@media (max-width: 1250px)'] = {
    LinktreeLinkRoot: {
      flexDirection: 'column',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: [0, 20, 0, 20],
      marginTop: 25,
    },
    linktreeLinkItem: {
      width: '100%',
      marginBottom: 15,
    },
    linktreeLink: {
      minHeight: height,
      borderRadius: 60,
    },
    labelDesktop: {
      display: 'none',
    },
  }

  /*------------------------------
  Custom 615px
  ------------------------------*/
  output['@media (max-width: 615px)'] = {
    LinktreeLinkRoot: {
      maxWidth: 375,
    },
    linktreeLinkItem: {
      width: '100%',
      marginBottom: 15,
    },
    linktreeLink: {
      minHeight: height,
    },
    labelDesktop: {
      display: 'none',
    },
  }

  return output
}

export default style
